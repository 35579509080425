
.pageContainer {
  min-height: 100vh;
  background: linear-gradient(135deg, #f0f4ff 0%, #e6e9fd 100%);
  padding: 3rem 1rem;

    
    @media (min-width: 640px) {
      padding: 3rem 1.5rem;
    }
    
    @media (min-width: 1024px) {
      padding: 3rem 2rem;
    }
  }

  .card { 
    max-width: 1200px;
    margin: 0 auto;
    background-color: white;
    border-radius: 0.75rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;  margin-top: 100px;
  }

  .header {
    background-color: #474746;
    padding: 1.5rem;
    color: white;
    
    h1 {
      font-size: 1.5rem;
      font-weight: 700;
      margin: 0;
      line-height: 1.2;
      font-family: virat;
    }
    
    p {
      color: rgba(255, 255, 255, 0.8);
      margin-top: 0.5rem;
      font-size: 0.9rem; font-family: bwvirat;
    }
  }
  

  .message {
    text-align: center;
    padding: 2rem;
    
    &.error {
      color: #c25f03;
    }
    
    &.empty {
      color: #6b7280;
    }
  }
  .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16rem;
  }
  
  .spinner {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 4px solid rgba(79, 70, 229, 0.1);
    border-top-color: #4f46e5;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  

  .message {
    text-align: center;
    padding: 2rem;
    
    &.error {
      color: #dc2626;
    }
    
    &.empty {
      color: #6b7280;
    }
  }
  

  .tableContainer {
    overflow-x: auto;
    
    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
    }
    
    th {
      position: sticky;
      top: 0;
      background-color: #f9fafb;
      color: #6b7280;
      font-size: 0.75rem;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      padding: 0.75rem 1.5rem;
      text-align: left;
      border-bottom: 1px solid #e5e7eb;
    }
    
    td {
      padding: 1rem 1.5rem;
      font-size: 0.875rem;
      color: #374151;
      border-bottom: 1px solid #f3f4f6;
      white-space: nowrap;
      font-family: virat;
      &.message {
        white-space: normal;
        max-width: 20rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
text-align: left;
        font-family: virat;
      }
    }
    
    tr {
      transition: background-color 0.2s;
      
      &:hover {
        background-color: #f9fafb;
      }
    }
  }
  

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    background-color: #f9fafb;
    border-top: 1px solid #e5e7eb;
  }
  


  .counter {
    font-size: 0.875rem;
    color: #6b7280;
  }

  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  

.loginContainer {
  padding: 2.5rem;
  max-width: 400px;
  margin: 0 auto;
}

.loginTitle {
  font-size: 32px;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 1.5rem;
  text-align: center;
  font-family: virat;
}

.loginError {
  background-color: #fee2e2;
  color: #b91c1c;
  padding: 0.75rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.loginForm {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.formGroup {
  display: flex;
  flex-direction: column;
  
  label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #4b5563;
    margin-bottom: 0.1rem; 
     font-family: virat;
  }
}

.input {
  padding: 0.625rem 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  transition: border-color 0.2s, box-shadow 0.2s;
  
  &:focus {
    outline: none;
    border-color: #4f46e5;
    box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.2);
  }
  
  &::placeholder {
    color: #9ca3af;
    font-size: 14px;
  }
}


/* Container for centering the form */
.loginForm {
  max-width: 400px;
  width: 90%;
  margin: 50px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Form Group - Labels and Inputs */
.formGroup {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.formGroup label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

/* Input Fields */
.input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: border 0.3s;
}
label {
  width: 100%;
  padding-bottom: 10px;
 
  font-size: 16px;

}

.input:focus {
  border-color: #007bff;
  outline: none;
}

/* Submit Button */
.button {
  width: 100%;
  padding: 10px;
  background: #454645;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;
}

.button:hover {
  background: #0056b3;
}

/* Responsive Design */
@media (max-width: 480px) {
  .loginForm {
    padding: 15px;
  }

  .input {
    font-size: 14px;
  }

  .button {
    font-size: 14px;
  }
}

.button { width: auto;
  background-color: #333027;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #4338ca;
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.5);
  }
}

.logoutButton{
  background-color: #5f2503;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-left: 20px;
  &:hover {
    background-color: #b64605;
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.5);
  }
}

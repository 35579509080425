.faqContainer {
    max-width: 1000px;
    margin: auto;
    padding: 20px;
    margin-bottom: 40px;
  }
  
  .heading {
    
      font-weight: bold;
      font-family: bwvirat;
      margin: 20px;
      font-size: 48px;
      color: rgb(0, 0, 0);
text-align: center;
 
    
  }
  
  .faqList {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .faqItem {
    background: #f9f9f9;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .faqQuestion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    background: #efefef7c;
    color: rgb(0, 0, 0);
    border-radius: 8px;
    border: 1px solid rgb(255, 119, 0);
 font-family: virat;
  }
  
  .faqAnswer {
    padding: 15px;
    background: #fff;
    font-size: 16px;
    line-height: 1.5; 
    font-family: bwviratlight;
  text-align: justify;
  }
  
  @media (max-width: 600px) {
    .faqQuestion {
      font-size: 16px;
    }
  
    .faqAnswer {
      font-size: 14px;
    }
    .heading{
      font-size: 24px;
    }
  }
  